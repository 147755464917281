:root {
    /* Colors */
    --background-light: #f2f4f6;
    --background-dark: #0d0e10;

    --text-light: #000;
    --text-dark: #fff;

    --divider-light: #abacb2;
    --divider-dark: #414246;

    --accent-light: #6f3aff4f;
    --accent-dark: #946dff5d;

    --accent-border-light: #7a7e8222;
    --accent-border-dark: #6a71711f;

    --glass-background: rgba(255, 255, 255, 0.2);
    --nav-glass-background: rgba(255, 255, 255, 0.349);

    --active-switch-background: #7f52fb;
    --switch-border: #c1c1c12b;

    --bright-glass-background-light: rgba(255, 255, 255, 0.561);
    --bright-glass-background-dark: rgba(36, 36, 36, 0.464);

    --accent-glass-background: #7f52fb6f;
    --banner-background: linear-gradient(337deg, rgba(126, 48, 181, 0.216) 0%, rgba(116, 135, 255, 0.364) 51%, rgba(127, 82, 251, 0.289) 100%);

    --success: #baffc0;
    --danger: #ffc7c7;

    --like: #ff6c9d;
    --bookmark: #e7b914;

    /* Shadows */
    --default-shadow-light: 0 0.4rem 5rem rgba(6, 7, 19, 0.129);
    --default-shadow-dark: 0 0.2rem 5rem rgba(230, 240, 247, 0.128);

    --minimal-shadow-light: 0 0 1.2rem rgba(6, 7, 19, 0.044);
    --minimal-shadow-dark: 0 0 1.4rem rgba(230, 240, 247, 0.033);

    /* EFFECTS */
    --blur: blur(2.3rem);

    /* Scales */
    --full-width: 138rem;
}

/* Font size initial set made 62.5% to ensure 1rem=10px for ease of use */
/* DEFAULTS RESET */
html {
    box-sizing: border-box;
    font-size: 62.5%;
}
*, *:before, *:after {
    box-sizing: inherit;
    font-family: "Ubuntu", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    transition: 1s cubic-bezier(0.45, 0.05, 0.55, 0.95);
}
body, h1, h2, h3, h4, h5, h6, p, ol, ul, figure, span, a, em {
    margin: 0;
    padding: 0;
    line-height: 110%;
    text-decoration: none;
}
body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem 2.4rem;
    background-color: var(--current-theme-background);
}

/* ------------------------------------------------------ TYPOGRAPHY ------------------------------------------------------*/

h1, h2, h3, h4, h5, h6 {
    color: var(--current-theme-text);
    font-weight: 500;
}
h1 {
    font-size: 4.2rem;
    font-weight: 700;
}
h2 {
    font-size: 3.8rem;
}
h3 {
    font-size: 3.2rem;
}
h4 {
    font-size: 2.6rem;
}
h5 {
    font-size: 2.2rem;
}
h6 {
    font-size: 2rem;
}
p {
    font-size: 1.8rem;
    font-weight: 400;
    color: var(--current-theme-text);
}
a {
    font-size: 1.8rem;
    font-weight: 500;
    color: var(--active-switch-background);
    transition: 0.3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
}
a:hover {
    color: var(--current-theme-text);
    text-decoration: underline;
}
em {
    font-family: serif;
    font-size: 1.8rem;
    font-weight: 400;
    color: var(--current-theme-text);
}
.toast>p {
    width: 100%;
    text-align: center;
    color: var(--text-light);
}


/*------------------------------------------------------ BASE ELEMENTS ------------------------------------------------------*/

hr {
    width: 100%;
    height: 0.2rem;
    background-color: var(--current-theme-divider);
    border: none;
}
label {
    width: 100%;
    font-size: 1.8rem;
    font-weight: 400;
    color: var(--current-theme-text);
}
input {
    width: 100%;
    font-size: 1.8rem;
    font-weight: 400;
    color: var(--current-theme-text);
    background-color: var(--current-theme-background);
    border: var(--current-theme-divider) 0.2rem solid;
    border-radius: 0.6rem;
    padding: 0.6rem 1.2rem;
    outline: none;
    transition: 0.3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
}
input:focus {
    border: var(--active-switch-background) 0.2rem solid;
}


/*------------------------------------------------------ BUTTONS ------------------------------------------------------*/

button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Ubuntu", sans-serif;
    font-weight: 700;
    font-size: 1.8rem;
    color: var(--current-theme-text);
    background-color: transparent;
    padding: 0.4rem 1rem;
    width: fit-content;
    min-width: 3.6rem;
    min-height: 3.6rem;
    border: none;
    border-radius: 32rem;
    transition: 0.3s cubic-bezier(0.6, -0.28, 0.74, 0.05);
    box-shadow: none;
}
button:hover {
    cursor: pointer;
    transform: scale(1.1);
    background-color: var(--current-theme-accent);
    box-shadow: var(--current-minimal-shadow);
}
button.form-button {
    width: 100%;
    background: var(--current-theme-accent);
}
button.form-button:hover {
    background: var(--active-switch-background);
    transform: none;
}
button.card-button {
    height: 4.2rem;
    width: 4.2rem;
    border-radius: 1.2rem;
    background-color: var(--current-bright-glass-background);
    backdrop-filter: var(--blur);
    box-shadow: var(--current-minimal-shadow);
}
button.card-button.liked {
    background-color: var(--like);
    border-radius: 10rem;
}
button.card-button.bookmarked {
    background-color: var(--bookmark);
    border-radius: 10rem;
}
button.card-button:hover {
    background-color: var(--accent-glass-background);
}
button.banner-button {
    padding: 0.4rem 2.2rem;
    background-color: var(--current-theme-accent);
}
button.banner-button:hover {
    background-color: var(--active-switch-background);
    transform: none;
}
button.banner-button.secondary, button.form-button.secondary {
    background-color: transparent;
    max-width: 26rem;
}
button.banner-button.secondary:hover, button.form-button.secondary:hover {
    background-color: var(--active-switch-background);
}
button.switch-button {
    font-size: 1.6rem;
    min-width: 2rem;
    min-height: 2rem;
    padding: 0.6rem 1.2rem;
}
button.switch-button:hover, button.loadButton:hover {
    cursor: pointer;
    transform: none;
}
button.active-switch {
    background-color: var(--active-switch-background);
    box-shadow: var(--current-minimal-shadow);
}
button.loadButton {
    padding: 0.2rem 1.2rem;
    background-color: var(--current-bright-glass-background);
}
button.loadButton:hover {
    background-color: var(--active-switch-background);
}
button.active-switch:hover {
    cursor: not-allowed;
    background-color: var(--active-switch-background);
}


/*------------------------------------------------------ LAYOUT ELEMENTS ------------------------------------------------------*/

header {
    position: initial;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: var(--full-width);
    padding: 3.2rem 0rem;
    border: 0.1rem solid var(--current-theme-divider);
    border-width: 0 0 0.1rem 0;
    box-shadow: none;
    z-index: 100;
}
nav {
    display: flex;
    align-items: center;
    gap: 3.2rem;
    padding: 0.6rem 1rem;
    width: fit-content;
    background: var(--nav-glass-background);
    backdrop-filter: var(--blur);
    box-shadow: var(--current-default-shadow);
    border: var(--switch-border) 0.2rem solid;
    border-radius: 10rem;
}
main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: var(--full-width);
    padding: 3.2rem 1.6rem;
}



/*------------------------------------------------------ COMPONENTS ------------------------------------------------------*/

article {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 32%;
    aspect-ratio: 16 / 9;
    border-radius: 1.2rem;
    background-color: var(--current-theme-background);
    box-shadow: var(--current-minimal-shadow);
}
article:hover {
    cursor: pointer;
    box-shadow: var(--current-default-shadow);
}
article:hover > .card-top-nav, article:hover > .card-bottom-nav {
    opacity: 1;
    transform: scale(1);
}
article:hover > img {
    filter: saturate(1.2);
    filter: contrast(1.2);
}
form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.8rem;
    width: 100%;
    max-width: 34rem;
    padding: 2.4rem;
    border-radius: 1.2rem;
    background: var(--current-theme-background);
    box-shadow: var(--current-minimal-shadow);
}
.banner {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: var(--full-width);
    gap: 1.8rem;
    padding: 3.6rem 4.2rem 2.6rem 4.2rem;
    margin: 3.2rem 0;
    background: var(--banner-background);
    border-radius: 1.2rem;
    box-shadow: var(--current-minimal-shadow);
}
.toast-container {
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
    z-index: 9999;
}
.overlayDialog {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: var(--glass-background);
    backdrop-filter: var(--blur);
    z-index: 1000;
}
.profile-dropdown {
    position: absolute;
    top: 6rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    background: var(--current-theme-background);
    box-shadow: var(--current-default-shadow);
    border: var(--switch-border) 0.2rem solid;
    border-radius: 1rem;
    z-index: 100;
}
.bookmarks-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.8rem;
    width: 100%;
    max-width: 90rem;
    padding: 2.4rem;
    margin: 0 2rem;
    border-radius: 1rem;
    background: var(--current-theme-background);
    box-shadow: var(--current-default-shadow);
}
.bookmarks-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
    max-height: 65vh;
    overflow: overlay;
}
.floating-controls {
    position: fixed;
    bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.4rem;
    z-index: 20;
}
.filter-box {
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;
    max-width: 80rem;
    align-items: center;
    justify-content: space-around;
    padding: 1.2rem 1.4rem;
    background: var(--current-theme-background);
    box-shadow: var(--current-default-shadow);
    border: var(--switch-border) 0.2rem solid;
    border-radius: 1rem;
}
.card-top-nav, .card-bottom-nav {
    position: absolute;
    display: flex;
    padding: 0.8rem 0.8rem;
    transform: scale(0.01);
    opacity: 0;
    transition: 0.3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
    z-index: 10;
}
.card-top-nav {
    justify-content: flex-end;
    top: 0;
    right: 0;
}
.card-bottom-nav {
    justify-content: space-between;
    bottom: 0;
    left: 0;
    right: 0;
}
.feed-row {
    display: flex;
    gap: 2rem;
    width: 100%;
    max-width: var(--full-width);
    margin: 1.2rem 0;
}
.controlSwitch {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    padding: 0.3rem;
    width: fit-content;
    border: var(--switch-border) 0.2rem solid;
    border-radius: 6rem;
}
.filterIndicator {
    display: flex;
    padding: 0.6rem 1.2rem;
}
.controls-block {
    display: flex;
    align-items: center;
    gap: 0.4rem;
}
.bookmark {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.2rem;
    background-color: var(--current-bright-glass-background);
    border-radius: 1.2rem;
    box-shadow: var(--current-minimal-shadow);
    gap: 1rem;
    max-width: 49%;
}
img.card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1.2rem;
}
img.bookmark-image {
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    border-radius: 0.8rem;
}
.toast {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 24rem;
    max-width: 34rem;
    padding: 1rem 1.6rem;
    margin-top: 3.2rem;
    margin-bottom: -2.6rem;
    background-color: none;
    border: var(--switch-border) 0.2rem solid;
    border-radius: 0.8rem;
    box-shadow: var(--current-default-shadow);
}

/*------------------------------------------------------ STATES ------------------------------------------------------*/
.hidden {
    display: none;
}
.sticky-header {
    padding: 1.2rem 1.4rem;
    position: sticky;
    top: 0;
    background-color: var(--glass-background);
    backdrop-filter: var(--blur);
    box-shadow: var(--current-minimal-shadow);
    border-radius: 0 0 1.2rem 1.2rem;
}
.success {
    background-color: var(--success);
}
.danger {
    background-color: var(--danger);
}



/*------------------------------------------------------ ANIMATIONS ------------------------------------------------------*/
.tilt-in-top {
	-webkit-animation: tilt-in-top 0.6s ease-out both;
	        animation: tilt-in-top 0.6s ease-out both;
}
.slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) both;
	        animation: slide-in-bottom 0.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) both;
}
.slide-out-blurred-right {
	-webkit-animation: slide-out-blurred-right 1s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
	        animation: slide-out-blurred-right 1s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
}
.heartbeat {
	-webkit-animation: heartbeat 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) infinite both;
	        animation: heartbeat 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) infinite both;
}
.jello-vertical {
	-webkit-animation: jello-vertical 0.8s 2 both;
	        animation: jello-vertical 0.8s 2 both;
}
.fade-out-bck {
	-webkit-animation: fade-out-bck 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: fade-out-bck 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes tilt-in-top {
    0% {
      -webkit-transform: rotateY(30deg) translateY(-300px) skewY(-30deg);
              transform: rotateY(30deg) translateY(-300px) skewY(-30deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
              transform: rotateY(0deg) translateY(0) skewY(0deg);
      opacity: 1;
    }
}
@keyframes tilt-in-top {
    0% {
      -webkit-transform: rotateY(30deg) translateY(-300px) skewY(-30deg);
              transform: rotateY(30deg) translateY(-300px) skewY(-30deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
              transform: rotateY(0deg) translateY(0) skewY(0deg);
      opacity: 1;
    }
}


@-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
              transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
              transform: translateY(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
}
@keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
              transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
              transform: translateY(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
}


@-webkit-keyframes slide-out-blurred-right {
    0% {
      -webkit-transform: translateX(0) scaleY(1) scaleX(1);
              transform: translateX(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(1000px) scaleX(2) scaleY(0.2);
              transform: translateX(1000px) scaleX(2) scaleY(0.2);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
}
@keyframes slide-out-blurred-right {
    0% {
      -webkit-transform: translateX(0) scaleY(1) scaleX(1);
              transform: translateX(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(1000px) scaleX(2) scaleY(0.2);
              transform: translateX(1000px) scaleX(2) scaleY(0.2);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
}


@-webkit-keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
}
@keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
}


@-webkit-keyframes jello-vertical {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
              transform: scale3d(0.75, 1.25, 1);
    }
    40% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
              transform: scale3d(1.25, 0.75, 1);
    }
    50% {
      -webkit-transform: scale3d(0.85, 1.15, 1);
              transform: scale3d(0.85, 1.15, 1);
    }
    65% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
              transform: scale3d(1.05, 0.95, 1);
    }
    75% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
              transform: scale3d(0.95, 1.05, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
}
@keyframes jello-vertical {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
              transform: scale3d(0.75, 1.25, 1);
    }
    40% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
              transform: scale3d(1.25, 0.75, 1);
    }
    50% {
      -webkit-transform: scale3d(0.85, 1.15, 1);
              transform: scale3d(0.85, 1.15, 1);
    }
    65% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
              transform: scale3d(1.05, 0.95, 1);
    }
    75% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
              transform: scale3d(0.95, 1.05, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
}

@-webkit-keyframes fade-out-bck {
    0% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateZ(-80px);
              transform: translateZ(-80px);
      opacity: 0;
    }
}
@keyframes fade-out-bck {
    0% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateZ(-80px);
              transform: translateZ(-80px);
      opacity: 0;
    }
}
  
  

/*------------------------------------------------------ ICONS ------------------------------------------------------*/
/* Currently used set: https://icon-sets.iconify.design/ion/ */
.icon {
    display: inline-block;
    background-color: var(--current-theme-text);
    -webkit-mask-image: var(--svg);
    mask-image: var(--svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
}

.ion--moon-outline {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M160 136c0-30.62 4.51-61.61 16-88C99.57 81.27 48 159.32 48 248c0 119.29 96.71 216 216 216c88.68 0 166.73-51.57 200-128c-26.39 11.49-57.38 16-88 16c-119.29 0-216-96.71-216-216'/%3E%3C/svg%3E");
}
.ion--sunny-outline {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-miterlimit='10' stroke-width='32' d='M256 48v48m0 320v48m147.08-355.08l-33.94 33.94M142.86 369.14l-33.94 33.94M464 256h-48m-320 0H48m355.08 147.08l-33.94-33.94M142.86 142.86l-33.94-33.94'/%3E%3Ccircle cx='256' cy='256' r='80' fill='none' stroke='%23000' stroke-linecap='round' stroke-miterlimit='10' stroke-width='32'/%3E%3C/svg%3E");
}
.ion--person-outline {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96'/%3E%3Cpath fill='none' stroke='%23000' stroke-miterlimit='10' stroke-width='32' d='M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304Z'/%3E%3C/svg%3E");
}
.ion--open-outline {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M384 224v184a40 40 0 0 1-40 40H104a40 40 0 0 1-40-40V168a40 40 0 0 1 40-40h167.48M336 64h112v112M224 288L440 72'/%3E%3C/svg%3E");
}
.ion--heart-outline {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81c-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0 0 18 0c96.26-65.34 184.09-143.09 183-252.42c-.54-52.67-42.32-96.81-95.08-96.81'/%3E%3C/svg%3E");
}
.ion--bookmark-outline {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M352 48H160a48 48 0 0 0-48 48v368l144-128l144 128V96a48 48 0 0 0-48-48'/%3E%3C/svg%3E");
}
.ion--logo-google {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23000' d='m473.16 221.48l-2.26-9.59H262.46v88.22H387c-12.93 61.4-72.93 93.72-121.94 93.72c-35.66 0-73.25-15-98.13-39.11a140.08 140.08 0 0 1-41.8-98.88c0-37.16 16.7-74.33 41-98.78s61-38.13 97.49-38.13c41.79 0 71.74 22.19 82.94 32.31l62.69-62.36C390.86 72.72 340.34 32 261.6 32c-60.75 0-119 23.27-161.58 65.71C58 139.5 36.25 199.93 36.25 256s20.58 113.48 61.3 155.6c43.51 44.92 105.13 68.4 168.58 68.4c57.73 0 112.45-22.62 151.45-63.66c38.34-40.4 58.17-96.3 58.17-154.9c0-24.67-2.48-39.32-2.59-39.96'/%3E%3C/svg%3E");
}
.ion--duplicate-outline {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Crect width='336' height='336' x='128' y='128' fill='none' stroke='%23000' stroke-linejoin='round' stroke-width='32' rx='57' ry='57'/%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='m383.5 128l.5-24a56.16 56.16 0 0 0-56-56H112a64.19 64.19 0 0 0-64 64v216a56.16 56.16 0 0 0 56 56h24m168-168v160m80-80H216'/%3E%3C/svg%3E");
}
.ion--notifications-outline {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M427.68 351.43C402 320 383.87 304 383.87 217.35C383.87 138 343.35 109.73 310 96c-4.43-1.82-8.6-6-9.95-10.55C294.2 65.54 277.8 48 256 48s-38.21 17.55-44 37.47c-1.35 4.6-5.52 8.71-9.95 10.53c-33.39 13.75-73.87 41.92-73.87 121.35C128.13 304 110 320 84.32 351.43C73.68 364.45 83 384 101.61 384h308.88c18.51 0 27.77-19.61 17.19-32.57M320 384v16a64 64 0 0 1-128 0v-16'/%3E%3C/svg%3E");
}
.ion--exit-outline {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M320 176v-40a40 40 0 0 0-40-40H88a40 40 0 0 0-40 40v240a40 40 0 0 0 40 40h192a40 40 0 0 0 40-40v-40m64-160l80 80l-80 80m-193-80h273'/%3E%3C/svg%3E");
}
.ion--bookmarks-outline {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='none' stroke='%23000' stroke-linejoin='round' stroke-width='32' d='M128 80V64a48.14 48.14 0 0 1 48-48h224a48.14 48.14 0 0 1 48 48v368l-80-64'/%3E%3Cpath fill='none' stroke='%23000' stroke-linejoin='round' stroke-width='32' d='M320 96H112a48.14 48.14 0 0 0-48 48v352l152-128l152 128V144a48.14 48.14 0 0 0-48-48Z'/%3E%3C/svg%3E");
}
.ion--trash-outline {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='m112 112l20 320c.95 18.49 14.4 32 32 32h184c17.67 0 30.87-13.51 32-32l20-320'/%3E%3Cpath fill='%23000' stroke='%23000' stroke-linecap='round' stroke-miterlimit='10' stroke-width='32' d='M80 112h352'/%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M192 112V72h0a23.93 23.93 0 0 1 24-24h80a23.93 23.93 0 0 1 24 24h0v40m-64 64v224m-72-224l8 224m136-224l-8 224'/%3E%3C/svg%3E");
}

.icon-s {
    width: 1.4rem;
    height: 1.4rem;
}
.icon-m {
    width: 1.8rem;
    height: 1.8rem;
}
.icon-l {
    width: 2.2rem;
    height: 2.2rem;
}




/* ------------------------------------------------------ SMALL SCREENS ------------------------------------------------------ */

@media (max-width: 800px) {
    main {
        padding: 2.2rem 0;
    }
    .feed-row {
        flex-direction: column;
        align-items: center;
    }
    article {
        max-width: 100%;
    }
    .floating-controls {
        bottom: 0rem;
    }
    nav {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 0.6rem;
        padding: 0.6rem;
        width: 100%;
        max-width: 90vw;
        border-radius: 1rem;
    }
    .bookmarks-list {
        max-height: 65vh;
        overflow: scroll;
    }
    .bookmark {
        max-width: 100%;
    }
}